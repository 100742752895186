// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/Cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-and-resellers-js": () => import("./../../../src/pages/PartnersAndResellers.js" /* webpackChunkName: "component---src-pages-partners-and-resellers-js" */),
  "component---src-pages-pbi-data-portal-js": () => import("./../../../src/pages/PBIDataPortal.js" /* webpackChunkName: "component---src-pages-pbi-data-portal-js" */),
  "component---src-pages-pbi-data-portal-privacy-js": () => import("./../../../src/pages/PBIDataPortalPrivacy.js" /* webpackChunkName: "component---src-pages-pbi-data-portal-privacy-js" */),
  "component---src-pages-pbi-data-portal-terms-js": () => import("./../../../src/pages/PBIDataPortalTerms.js" /* webpackChunkName: "component---src-pages-pbi-data-portal-terms-js" */),
  "component---src-pages-pbi-portal-js": () => import("./../../../src/pages/PBIPortal.js" /* webpackChunkName: "component---src-pages-pbi-portal-js" */),
  "component---src-pages-pbi-portal-privacy-js": () => import("./../../../src/pages/PBIPortalPrivacy.js" /* webpackChunkName: "component---src-pages-pbi-portal-privacy-js" */),
  "component---src-pages-pbi-portal-terms-js": () => import("./../../../src/pages/PBIPortalTerms.js" /* webpackChunkName: "component---src-pages-pbi-portal-terms-js" */),
  "component---src-pages-pbi-scorecards-js": () => import("./../../../src/pages/PBIScorecards.js" /* webpackChunkName: "component---src-pages-pbi-scorecards-js" */),
  "component---src-pages-pbi-scorecards-privacy-js": () => import("./../../../src/pages/PBIScorecardsPrivacy.js" /* webpackChunkName: "component---src-pages-pbi-scorecards-privacy-js" */),
  "component---src-pages-pbi-scorecards-terms-js": () => import("./../../../src/pages/PBIScorecardsTerms.js" /* webpackChunkName: "component---src-pages-pbi-scorecards-terms-js" */),
  "component---src-pages-pbi-smart-pivot-js": () => import("./../../../src/pages/PBISmartPivot.js" /* webpackChunkName: "component---src-pages-pbi-smart-pivot-js" */),
  "component---src-pages-pbi-smart-pivot-privacy-js": () => import("./../../../src/pages/PBISmartPivotPrivacy.js" /* webpackChunkName: "component---src-pages-pbi-smart-pivot-privacy-js" */),
  "component---src-pages-pbi-smart-pivot-terms-js": () => import("./../../../src/pages/PBISmartPivotTerms.js" /* webpackChunkName: "component---src-pages-pbi-smart-pivot-terms-js" */),
  "component---src-pages-power-bi-robots-js": () => import("./../../../src/pages/PowerBIRobots.js" /* webpackChunkName: "component---src-pages-power-bi-robots-js" */),
  "component---src-pages-power-bi-robots-privacy-js": () => import("./../../../src/pages/PowerBIRobotsPrivacy.js" /* webpackChunkName: "component---src-pages-power-bi-robots-privacy-js" */),
  "component---src-pages-power-bi-robots-terms-js": () => import("./../../../src/pages/PowerBIRobotsTerms.js" /* webpackChunkName: "component---src-pages-power-bi-robots-terms-js" */),
  "component---src-pages-pro-js": () => import("./../../../src/pages/Pro.js" /* webpackChunkName: "component---src-pages-pro-js" */),
  "component---src-pages-pro-privacy-js": () => import("./../../../src/pages/ProPrivacy.js" /* webpackChunkName: "component---src-pages-pro-privacy-js" */),
  "component---src-pages-pro-terms-js": () => import("./../../../src/pages/ProTerms.js" /* webpackChunkName: "component---src-pages-pro-terms-js" */),
  "component---src-pages-site-privacy-js": () => import("./../../../src/pages/SitePrivacy.js" /* webpackChunkName: "component---src-pages-site-privacy-js" */),
  "component---src-pages-site-terms-js": () => import("./../../../src/pages/SiteTerms.js" /* webpackChunkName: "component---src-pages-site-terms-js" */)
}

